<template>
    <div class="bg">
        <v-container class="pt-4 mt-0">
            <v-row>
                <v-col cols="12">
                    <h1 style="padding-top:0; text-align: center;">Tradutor</h1>
                    <v-card>
                        <v-card-text>
                            <v-form>
                                <v-row class="justify-center">
                                    <v-col cols="5"><v-select :items="selectIdiomarOrigem" v-model="registro.idioma_origem" hide-details label="Idioma de origem:"/></v-col>
                                    <v-col cols="1" class="text-center justify-center ma-0 px-0"><v-btn icon class="mt-1" large @click="inverterIdioma"><v-icon>mdi-swap-horizontal</v-icon></v-btn></v-col>
                                    <v-col cols="5"><v-select :items="selectIdiomarDestino" v-model="registro.idioma_destino" hide-details label="Idioma de destino:"/></v-col>
                                    <v-col cols="11" md="11"><v-text-field :loading="carregando" @keydown.enter="traduzir" v-model="registro.texto" append-outer-icon="mdi-magnify" @click:append-outer="traduzir" hide-details label="Traduzir:"/></v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" v-if="erro.visivel"><v-alert dismissible v-model="erro.visivel" type="error">{{erro.texto}}</v-alert></v-col>
                <template v-for="(cf, i) in historico">
                    <v-col cols="12" sm="6" lg="4" xl="3" :key="i">
                        <v-card>
                            <v-row>
                                <v-col cols="10">
                                    <v-card-text class="pt-3 my-0" v-html="`${i+1}. ${cf.texto_origem}`" style="white-space: pre-line; color: #000;"/>
                                </v-col>
                                <v-col cols="2" class="text-right">
                                    <v-btn icon @click="excluir(cf.id_traducao)"><v-icon>mdi-delete</v-icon></v-btn>
                                </v-col>
                            </v-row>
                            <v-divider/>
                            <v-row>
                                <v-col cols="10">
                                    <v-card-text v-show="cf.resposta_exibir" v-html="cf.texto_destino" style="white-space: pre-line;"/>
                                </v-col>
                                <v-col cols="2" class="text-right">
                                    <v-btn icon @click="speech(cf.id_traducao)"><v-icon>mdi-play</v-icon></v-btn>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </template>
                <TradutorHistorico/>
            </v-row>
        </v-container>
    </div>
</template>

<script>

import axios from "axios";
import {mapState} from "vuex";
import TradutorHistorico from "@/Views/Tradutor/TradutorHistorico.vue";
export default {
    name: "Tradutor",
    components: {TradutorHistorico},
    data: () => ({
        erro : {
            visivel : false,
            texto : ""
        },
        historico : [],
        registro : {
            idioma_origem : 'PT',
            idioma_destino : 'EN-US',
            texto : ''
        },
        selectIdiomarOrigem : [
            {value:'PT', valueRef:"PT-BR", text : 'Português (Brasil)'},
            {value:'EN', valueRef:"EN-US", text : 'Inglês (Americano)'}
        ],
        selectIdiomarDestino : [
            {value:'PT-BR', valueRef:"PT", text : 'Português (Brasil)'},
            {value:'EN-US', valueRef:"EN", text : 'Inglês (Americano)'}
        ],
        carregando : false
    }),
    computed : {
        ...mapState(['apiUrl'])
    },
    methods : {
        speech(id_traducao) {
            axios.post(`${this.apiUrl}tradutor/traducao/speech`, {id_traducao}).then( (res) => {
                let audio = new Audio('data:audio/mpeg;base64,'+res.data)
                audio.play()
            })
        },
        inverterIdioma() {
            let origem  = this.selectIdiomarOrigem.filter((v) => v.value == this.registro.idioma_origem)[0].valueRef
            let destino = this.selectIdiomarDestino.filter((v) => v.value == this.registro.idioma_destino)[0].valueRef

            this.registro.idioma_origem  = destino
            this.registro.idioma_destino = origem
        },
        async traduzir() {
            this.erro.visivel = false
            if (this.registro.texto == "") {
                this.erro = {
                    visivel : true,
                    texto   : "Informe uma palavra ou texto a ser traduzido"
                }
                return
            }
            this.carregando = true
            await axios.post(`${this.apiUrl}tradutor/traduzir`, {...this.registro}).then( (res) => {
                this.historico.unshift({
                    id_traducao     : parseInt(res.data.id_traducao),
                    sei             : false,
                    texto_origem    : this.registro.texto,
                    texto_destino   : res.data.traducao,
                    resposta_exibir : true
                })
                this.registro.texto = ""
            })
            this.carregando = false
        },
        async excluir(id_traducao) {
            this.carregando = true
            axios.post(`${this.apiUrl}tradutor/traducao/excluir`, {id_traducao}).then( () => {
                this.historico = this.historico.filter((v) => {
                    return v.id_traducao != id_traducao
                })
            }).catch(e => {
                this.erro = {
                    visivel: true,
                    texto : this.erroFormatar(e)
                }
            })
            this.carregando = false
        }
    }
}
</script>

<style scoped>
    h1, h2 {color: #005FAB;}
    h1 {text-align: left; width: 100%; padding-top:50px;  font-weight: lighter; font-size: 40px;}
    h2 {font-size: 20px;}
    .bg {background-image: url("~@/assets/img/bg-tradutor.png"); background-repeat: no-repeat; background-position-x: -50px; background-position-y: -50px;}
</style>