<template>
    <div class="bg">
        <v-container class="pt-4 mt-0">
            <v-row>
                <v-col cols="12" v-if="erro.visivel"><v-alert dismissible v-model="erro.visivel" type="error">{{erro.texto}}</v-alert></v-col>
                <template v-for="(cf, i) in historico">
                    <v-col v-if="!cf.sei" cols="12" sm="6" lg="4" xl="3" :key="i">
                        <v-card>
                            <v-row>
                                <v-col cols="10">
                                    <v-card-text class="pt-3 my-0" v-html="`${i+1}. ${cf.texto_origem}`" style="white-space: pre-line; color: #000;"/>
                                </v-col>
                                <v-col cols="2" class="text-right">
                                    <v-btn icon @click="excluir(cf.id_traducao)"><v-icon>mdi-delete</v-icon></v-btn>
                                </v-col>
                            </v-row>
                            <v-divider/>
                            <v-card-text v-show="cf.resposta_exibir" v-html="cf.texto_destino" style="white-space: pre-line;"/>
                            <v-divider v-show="cf.resposta_exibir"/>
                            <v-card-actions>
                                <v-btn v-if="!cf.sei" text @click="sei(cf.id_traducao)"><v-icon left>mdi-check</v-icon> Eu sei</v-btn>
                                <v-spacer/>
                                <v-btn @click="speech(cf.id_traducao)" v-if="!cf.sei" text><v-icon>mdi-play</v-icon></v-btn>
                                <v-btn text @click="cf.resposta_exibir = !cf.resposta_exibir; naoSei(cf.id_traducao)"><v-icon left>mdi-eye</v-icon> {{!cf.resposta_exibir ? 'Traduzir' : 'Ocultar'}}</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </template>
                <template v-for="(cf, i) in historico">
                    <v-col v-if="cf.sei" cols="12" sm="6" lg="4" xl="3" :key="'s_'+i">
                        <v-card color="green" dark>
                            <v-row>
                                <v-col cols="10">
                                    <v-card-text class="pt-3 my-0" v-html="`${i+1}. ${cf.texto_origem}`" style="white-space: pre-line; color: #FFF;"/>
                                </v-col>
                                <v-col cols="2" class="text-right">
                                    <v-btn icon @click="excluir(cf.id_traducao)"><v-icon>mdi-delete</v-icon></v-btn>
                                </v-col>
                            </v-row>
                            <v-divider style="border-color: #FFFFFF33"/>
                            <v-card-actions>
                                <v-btn v-if="!cf.sei" text @click="sei(cf.id_traducao)"><v-icon left>mdi-check</v-icon> Eu sei</v-btn>
                                <v-btn text @click="naoSei(cf.id_traducao)"><v-icon left>mdi-eye</v-icon>Traduzir</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </template>
            </v-row>
        </v-container>
    </div>
</template>

<script>

import axios from "axios";
import {mapState} from "vuex";
export default {
    name: "TradutorHistorico",
    components: {},
    data: () => ({
        erro : {
            visivel : false,
            texto : ""
        },
        historico : [],
        carregando : false
    }),
    computed : {
        ...mapState(['baseUrl', 'apiUrl'])
    },
    methods : {
        speech(id_traducao) {
            axios.post(`${this.apiUrl}tradutor/traducao/speech`, {id_traducao}).then( (res) => {
                let audio = new Audio('data:audio/mpeg;base64,'+res.data)
                audio.play()
            })
        },
        traducaoListar() {
            axios.post(`${this.apiUrl}tradutor/traducao/listar`).then( (res) => {
                this.historico = res.data.lista
            })
        },
        sei(id_traducao) {
            this.carregando = true
            axios.post(`${this.apiUrl}tradutor/traducao/sei`, {id_traducao}).then( () => {
                let index = this.historico.findIndex((v) => {
                    return v.id_traducao === id_traducao
                })
                this.historico[index] = {
                    ...this.historico[index],
                    sei : true
                }
                this.erro = {
                    visivel: false,
                    texto : ''
                }
            }).catch(e => {
                this.erro = {
                    visivel: true,
                    texto : this.erroFormatar(e)
                }
            })
            this.carregando = false
        },
        naoSei(id_traducao) {
            this.carregando = true
            axios.post(`${this.apiUrl}tradutor/traducao/naosei`, {id_traducao}).then( () => {
                let index = this.historico.findIndex((v) => {
                    return v.id_traducao === id_traducao
                })
                this.historico[index] = {
                    ...this.historico[index],
                    sei : false
                }
                this.erro = {
                    visivel: false,
                    texto : ''
                }
            }).catch(e => {
                this.erro = {
                    visivel: true,
                    texto : this.erroFormatar(e)
                }
            })
            this.carregando = false
        },
        async excluir(id_traducao) {
            this.carregando = true
            axios.post(`${this.apiUrl}tradutor/traducao/excluir`, {id_traducao}).then( () => {
                this.historico = this.historico.filter((v) => {
                    return v.id_traducao != id_traducao
                })
            }).catch(e => {
                this.erro = {
                    visivel: true,
                    texto : this.erroFormatar(e)
                }
            })
            this.carregando = false
        }
    },
    activated() {
         this.traducaoListar()
    }
}
</script>

<style scoped>
    h1, h2 {color: #005FAB;}
    h1 {text-align: left; width: 100%; padding-top:50px;  font-weight: lighter; font-size: 40px;}
    h2 {font-size: 20px;}
    .bg {background-image: url("~@/assets/img/bg-tradutor.png"); background-repeat: no-repeat; background-position-x: -50px; background-position-y: -50px;}
</style>